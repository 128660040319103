import React, { useEffect } from 'react';
import { Image, ImageBackground, Text, TouchableOpacity, View } from 'react-native';
import { withGetScreen } from 'react-native-getscreen';
import Spinner from 'react-native-loading-spinner-overlay';
import { connect } from 'react-redux';
import { parse } from 'search-params';

import backgroundBoxImage from '../../assets/img/bg-invite-image.svg';
import logo from '../../assets/img/logo.png';
import { formLayout } from '../../generalStyles';
import Actions from '../../RouteActions';

import * as actions from './actions';

import styles from './styles';

const webStyles = {
  overflowWrap: {
    overflowWrap: 'normal',
  },
};

const Invite = (props) => {
  const {
    inviteData,
    location,
    match,
    spinnerVisible,
    isMobile,
    getInviteData,
    onUpdateStatus,
    getOrganizationIdByName,
    organizationData,
  } = props;
  const mobile = isMobile();
  let identifier = (match && match.params && match.params.identifier) || '';
  identifier = decodeURI(identifier).replace(/%2f/g, '/');
  const orgName =
    Object.keys(inviteData).length > 0 && inviteData.organization_name ? inviteData.organization_name : organizationData.name;
  const invitedByAdmin = Object.keys(inviteData).length > 0 && inviteData.invited_by_name !== 'Administrator';
  const orgLogo = organizationData.logo;
  useEffect(() => {
    const params = parse(location.search);
    getInviteData((params && params.invitation_token) || '');
  }, [location.search]);

  useEffect(() => {
    getOrganizationIdByName(identifier);
  }, [identifier]);

  const handleSignUp = () => {
    if (invitedByAdmin) {
      if (location.search.includes('invitation_token') && Object.keys(inviteData).length > 0 && inviteData.user_data) {
        onUpdateStatus(inviteData.user_data.id, inviteData.organization_name);
      } else {
        Actions.signUp();
      }
    } else {
      Actions.signUp({
        orgName: identifier,
      });
    }
  };

  const RootComp = ({ children }) => {
    if (invitedByAdmin) {
      return (
        <ImageBackground
          source={backgroundBoxImage}
          style={mobile ? styles.elementWrapperMobile : styles.elementWrapper}
        >
          {children}
        </ImageBackground>
      );
    } else {
      return <View style={mobile ? styles.plainWrapperMobile : styles.plainWrapper}>{children}</View>;
    }
  };

  return (
    <RootComp>
      {invitedByAdmin ? (
        <View style={mobile ? styles.logoWrapperMobile : styles.logoWrapper}>
          <Image source={logo} style={mobile ? styles.logoMobile : styles.logo} />
        </View>
      ) : (
        <View style={styles.headerBar}>
          {orgLogo ? (
            <Image source={orgLogo} style={styles.orgLogo} />
          ) : (
            <Text style={styles.companyInitials}>{orgName && orgName[0]}</Text>
          )}
          <Text style={styles.headerText}>{orgName}</Text>
        </View>
      )}
      <View style={mobile ? styles.containerMobile : styles.container}>
        {
          !spinnerVisible && (  invitedByAdmin ? (
            <View>
              <Text style={mobile ? styles.mainTextMobile : styles.mainText}>Congrats!</Text>
              <View style={styles.textContainer}>
                <Text
                  style={[formLayout.row, mobile ? styles.inviteTextMobile : styles.inviteText, webStyles.overflowWrap]}
                >
                  Hi {inviteData.full_name}, you&apos;ve been invited by&nbsp;
                  {inviteData.invited_by_name} to join
                  <Text style={[styles.boldText, webStyles.overflowWrap]}>
                    &nbsp;
                    {inviteData.organization_name}
                    &apos;s&nbsp;
                  </Text>
                  referral community.
                </Text>
              </View>
              <View style={styles.actionButtonContainer}>
                <Text style={styles.gainAccessText}>Accept your invite to gain access.</Text>
                <View style={styles.buttonWidthManager}>
                <TouchableOpacity onPress={() => handleSignUp()}>
                <View style={mobile ? styles.buttonWrapperMobile : styles.buttonWrapper}>
                    <Text style={mobile ? styles.acceptTextMobile : styles.acceptText}>ACCEPT</Text>
                </View>
                </TouchableOpacity>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View style={styles.textContainer}>
                <Text
                  style={[formLayout.row, mobile ? styles.inviteTextMobile : styles.inviteText, styles.textSecondary]}
                >
                  👋 Hi there!
                </Text>
                <Text
                  style={[formLayout.row, mobile ? styles.inviteTextMobile : styles.inviteText, styles.textSecondary]}
                >
                  Join
                  <Text style={styles.boldText}>
                    &nbsp;
                    {orgName && `${orgName}'${orgName.substr(-1) === 's' ? '' : 's'}`}
                    &nbsp;
                  </Text>
                  referral community.
                </Text>
              </View>
              <View style={styles.actionButtonContainer}>
                <Text style={[styles.gainAccessText, styles.textSecondary]}>Continue to gain access.</Text>
                <View style={styles.buttonWidthManager}>
                <TouchableOpacity onPress={() => Actions.signUp({ orgName: orgName })}>
                  <View
                    style={[mobile ? styles.buttonWrapperMobile : styles.buttonWrapper, { backgroundColor: '#0FBC71' }]}
                  >
                    <Text
                      style={[
                        mobile ? styles.acceptTextMobile : styles.acceptText,
                        { color: 'white', backgroundColor: 'none' },
                      ]}
                    >
                      Continue
                    </Text>
                  </View>
                </TouchableOpacity>
                </View>
              </View>
            </View>
          ))
        }
      </View>
      <Spinner visible={spinnerVisible} />
    </RootComp>
  );
};

const mapStateToProps = (state) => {
  return {
    formValues: (state.form.invite && state.form.invite.values) || {},
    inviteData: state.authReducer.inviteData,
    spinnerVisible: state.appReducer.spinnerVisible,
    organizationData: state.organizationsReducer.organizationId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInviteData: (token) => {
      dispatch(actions.getInviteData(token));
    },
    onUpdateStatus: (userId, orgName) => {
      dispatch(actions.updateUserStatus(userId, orgName));
    },
    getOrganizationIdByName: (orgName) => {
      dispatch(actions.getOrgIdByName(orgName));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(Invite));
