import { find, isEmpty, each } from 'lodash-es';
import { Dimensions, Platform } from 'react-native';
import moment from 'moment';
import validator from 'validator';
import { apiSeverUrl } from './services/baseService';

const LINKEDIN_SHARE_URL = 'https://www.linkedin.com/shareArticle?mini=true';
const TWITTER_SHARE_URL = 'https://x.com/intent/tweet';
const FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php?';
const WHATSAPP_SHARE_URL = 'https://api.whatsapp.com/send?';

export const getFacebookShareLink = (jobUrl) =>
  `${FACEBOOK_SHARE_URL}u=${encodeURIComponent(jobUrl)}`;

export const getLinkedInShareLink = (jobUrl) => `${LINKEDIN_SHARE_URL}&url=${encodeURIComponent(jobUrl)}`;

export const getWhatsAppShareLink = (jobUrl) => `${WHATSAPP_SHARE_URL}text=${encodeURIComponent(jobUrl)}`;

export const getTwitterShareLink = (jobUrl, text) =>
  `${TWITTER_SHARE_URL}?text=${encodeURIComponent(text)}&url=${encodeURIComponent(jobUrl)}`;

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const validateLinkedInUrl = (url) => {
  const linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_%-]+\/?$/i;
  return linkedinRegex.test(url);
};
export const validateUrl = (value, type) => {
  if (type === 'ln' || type === 'LinkedIn') {
    return validateLinkedInUrl(value);
  } else {
    return validator.isURL(value);
  }
};

export const normalizePrice = (inputValue) => {
  const value = (inputValue / 100).toString();
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d|.]/g, '');

  const normalize = (finalValue) => {
    const symbolsArray = finalValue.split('');

    const decimalPosition = symbolsArray.indexOf('.') === -1 ? symbolsArray.length : symbolsArray.indexOf('.');

    for (let x = decimalPosition - 3; x > 0; x = x - 3) {
      symbolsArray.splice(x, 0, ',');
    }

    return symbolsArray.length ? `$${symbolsArray.join('')}` : '';
  };

  if (onlyNums === '.') {
    return normalize(`0${onlyNums}`);
  }

  if ((onlyNums.match(/\./g) || []).length > 1) {
    return normalize(`${onlyNums.slice(0, -1)}`);
  }

  return normalize(onlyNums);
};

export const getDateTimeFromNow = (time) => {
  time = typeof time === 'number' ? moment.unix(time) : moment(time);
  const today = moment();
  const diff = today.diff(time, 'months');
  if (diff < 1) {
    let temp = time.fromNow();
    if (temp.indexOf('hour')) {
      temp = temp.replace('hour', 'hr');
    }
    return temp;
  }

  return time.format('MMM DD');
};

export const formatDate = (time, format = 'MMM DD') => {
  time = typeof time === 'number' ? moment.unix(time) : moment(time);
  return moment(time).format(format);
};

export const notification = {
  ref: {},
};

export const isUrl = (string) => {
  const regex = new RegExp(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
  );
  return string.match(regex);
};

export const youtubeParser = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.replace(/\s/g, '').match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

export const extendWithConnections = (contacts, connections) => {
  return contacts.map((contact) => {
    const connectionObj = find(connections, (connection) => {
      return connection.email === contact.email || connection.phone_number === contact.phone_number;
    });

    return isEmpty(connectionObj) ? contact : connectionObj;
  });
};

export const permissionsInterval = { interval: null };

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export function isIphoneX() {
  return Platform.OS === 'ios' && !Platform.isPad && !Platform.isTVOS && (height === 812 || width === 812);
}

export function ifIphoneX(iphoneXStyle, regularStyle) {
  if (isIphoneX()) {
    return iphoneXStyle;
  }
  return regularStyle;
}

export function calcPermissionPercentage(permissions) {
  const percentage = {
    contacts: 35,
    notification: 25,
    location: 10,
  };

  let sum = 0;

  each(permissions, (permissionValue, permissionLabel) => {
    sum += permissionValue ? percentage[permissionLabel] : 0;
  });

  return sum;
}

export function calcProfilePercentage(currentUser) {
  const links = currentUser.links_json ? JSON.parse(currentUser.links_json) : [];

  const linkedin = links.find((link) => link.type === 'ln');

  let sum = 0;

  if (currentUser.cover_image) {
    sum += 5;
  }

  if (currentUser.bio) {
    sum += 5;
  }

  if (currentUser.sync_google_people_contacts) {
    sum += 10;
  }

  if (linkedin && Boolean(linkedin.value)) {
    sum += 10;
  }

  return sum;
}

export function getUserFormParams(currentUser) {
  const links = currentUser.links_json ? JSON.parse(currentUser.links_json) : [{ type: 'ln' }];
  return {
    initialValues: {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      bio: currentUser.bio,
      email: currentUser.email,
      workplace_name: currentUser.workplace,
      company_position: currentUser.company_position,
      links_json: links,
      country_iso_code: currentUser.country_iso_code,
      state: currentUser.state,
      city: currentUser.city,
      zip: currentUser.zip,
      full_address: currentUser.full_address,
      zip_code: currentUser.zip,
      lat: currentUser.lat,
      lon: currentUser.lon,
      place_id: currentUser.place_id,
    },
    currentAvatar: currentUser.avatar_thumb,
    currentCoverImage: currentUser.cover_image,
  };
}

export function locationObjToStr(location) {
  let locArray = [];

  if (!location) {
    return '';
  }

  if (location.full_address) {
    return location.full_address.trim();
  }

  if (location.city && location.city.trim()) {
    locArray.push(location.city.trim());
  }
  if (location.state && location.state.trim()) {
    locArray.push(location.state.trim());
  }
  if (location.country_name && location.country_name.trim()) {
    locArray.push(location.country_name.trim());
  } else if (location.country && location.country.trim()) {
    locArray.push(location.country.trim());
  }

  return locArray.join(', ');
}

export function getOrganizationForUser(state) {
  const organizationObject = state.organizationsReducer.userOrganizations.filter(
    (organization) => organization.status === 1
  )[0];

  return (
    organizationObject && {
      id: organizationObject.organization.id,
      name: organizationObject.organization.name,
      logo_image_url: organizationObject.organization.logo_image_thumb,
    }
  );
}

export function getCurrentOrganizationForUser(state) {
  const organizationObject = state.authReducer.currentSelectedOrg;

  return organizationObject && organizationObject.label !== 'Boon'
    ? {
        id: organizationObject.id,
        name: organizationObject.label,
        logo_image_url: organizationObject.logo,
      }
    : {};
}

export function getCurrentOrganizationAccessForUser(state) {
  const currentOrgId = state.authReducer.currentSelectedOrg.id;
  const userOrg = state.organizationsReducer.userOrganizations.find(
    (userOrg) => userOrg.organization.id === currentOrgId
  );
  return userOrg && userOrg.role_name !== 'member';
}

export function isDateValid(date) {
  let dateMoment = moment(date);
  if (dateMoment.isValid()) {
    return true;
  } else {
    return false;
  }
}

export function isPhoneNumberValid(phone) {
  phone = phone.replace(/\s|-|/gi, '');
  phone = (phone[0] === '+' ? '' : '+') + phone;

  if (phone && validator.isMobilePhone(phone) && phone[0] === '+') return true;
  else return false;
}

export function googleSSOUrl(accessToken) {
  let form = document.createElement('form');
  form.method = 'POST';
  form.action = `${apiSeverUrl}/auth/google_oauth2?access_token=${accessToken}&from_web_app=${Platform.OS == 'web'}`;
  document.body.appendChild(form);
  form.submit();
}

export const normalizeCandidateLinkedinData = (linkedinData) => {
  return {
    title: linkedinData.position_groups.length > 0 ? linkedinData.position_groups[0].profile_positions[0].title : '',
    location: {
      full_address: linkedinData.location.default,
      country_name: linkedinData.location.country,
      city: linkedinData.location.city,
      state: linkedinData.location.state,
    },
    employer:
      linkedinData.position_groups.length > 0 ? linkedinData.position_groups[0].profile_positions[0].company : '',
    skills: linkedinData.skills.map((skill, idx) => {
      return {
        id: (idx + 1) * -1,
        name: skill,
        isNew: true,
      };
    }),
  };
};

export const checkStrengthQuestion = (strengthQuestions, diversity) =>
  strengthQuestions.title ||
  strengthQuestions.employer ||
  strengthQuestions.location ||
  strengthQuestions.skills.length ||
  strengthQuestions.joiningDate ||
  diversity;
