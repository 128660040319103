import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import Uploadimage from '../../assets/svg/uploadimage.jsx';
import { notification } from '../../helperFunctions.js';
import { MAX_RESUME_SIZE } from '../../constants/fileUpload.js'
import './ReactDropZone.css'

const ReactDropZone = ({ setFilesArray, filesArray }) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone(
    {
      accept: 'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword',

      maxSize: MAX_RESUME_SIZE,
      onDropRejected: (fileRejections) => {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === 'file-too-large') {
              notification.ref.show({ message: 'File is too large. File has to be lower than 4MB.', isError: true });
            }
          });
        });
      },
    })

  useEffect(() => {
    const validFiles = acceptedFiles.filter((file) => file.size <= MAX_RESUME_SIZE);
    let totalFiles = [...filesArray, ...validFiles]
    if (totalFiles.length > 5) {
      setFilesArray(totalFiles.slice(0, 5), 'filesUploaded')
      return notification.ref.show({ message: 'You are not allowed to upload more than 5 files', isError: true });
    }
    setFilesArray(totalFiles, 'filesUploaded')
  }, [acceptedFiles])


  return (
    <div className='react-dropzone-wrapper' {...getRootProps()}>
      <input {...getInputProps()} />
      <Uploadimage />
    </div>
  )
}

export default ReactDropZone
