import * as trackerActionsConstants from '../../constants/actions/trackerActions';
import * as browseActionsConstants from '../../constants/actions/browseActions';
import * as trackerService from '../../services/trackerService';
import { notification } from '../../helperFunctions';
import responseCodes from '../../constants/responseCodes';

export function applyJob(organizationId, params, successCallback = null, failureCallback = null) {
  return (dispatch) => {
    dispatch({ type: trackerActionsConstants.APPLY_JOB });
    trackerService
      .applyJob(organizationId, params)
      .then((response) => {
        if (response.data.response_code === responseCodes.OK) {

          dispatch({ type: trackerActionsConstants.GET_CANDIDATE_ID, payload: response.data.payload });
          dispatch({ type: trackerActionsConstants.APPLY_JOB_SUCCESS, payload: response.data.payload });
          dispatch({ type: browseActionsConstants.UPDATE_CANDIDATE_BROWSE_DATA });
          notification.ref.show({ message: 'Job applied successfully' });
          if (successCallback) successCallback();

        } else {
          if (failureCallback) failureCallback();
          dispatch({ type: trackerActionsConstants.APPLY_JOB_ERROR });
          notification.ref.show({ message: response.data.error, isError: true });
        }
        localStorage.removeItem('fromTracker');
        localStorage.removeItem('userId');
        localStorage.removeItem('subjectEmail');
      })
      .catch((error) => {
        dispatch({ type: trackerActionsConstants.APPLY_JOB_ERROR });
      });
  };
}
export const updateApplyJob =
  (params, successCallback = null) =>
  () => {
    trackerService
      .updateApplyJob(params)
      .then((response) => {
        if (response.data.response_code === responseCodes.OK) {
          notification.ref.show({ message: 'Strengthen questions for candidate added successfully!' });
          if (successCallback) successCallback();
        } else {
          notification.ref.show({ message: response.data.error, isError: true });
        }
      })
      .catch(() => {
        notification.ref.show({ message: 'Something went wrong', isError: true });
      });
  };
export function hidePdfFromUser(pdfId) {
  return (dispatch) => {
    dispatch({ type: trackerActionsConstants.HIDE_PDF_FROM_USER_REQUEST });
    trackerService
      .updatePDFStatus(pdfId)
      .then((res) => {
        if (res.status === 200) {
          notification.ref.show({ message: 'Pdf deleted successfully' });
          dispatch({ type: trackerActionsConstants.HIDE_PDF_FROM_USER_SUCCES, payload: pdfId });
        }
        if (res.status === 404) {
          notification.ref.show({ message: 'Something went wrong', isError: true });
        }
      })
      .catch((error) => {
        notification.ref.show({ message: 'Something went wrong', isError: true });
      });
  };
}
export function getSkills(organizationId) {
  return (dispatch) => {
    dispatch({ type: trackerActionsConstants.GET_SKILLS });
    trackerService
      .getSkills(organizationId)
      .then((response) => {
        if (response.data.response_code === responseCodes.OK) {
          dispatch({ type: trackerActionsConstants.GET_SKILLS_SUCCESS, payload: response.data.payload.skills });
        } else {
          dispatch({ type: trackerActionsConstants.GET_SKILLS_ERROR, payload: response.data.error });
          notification.ref.show({ message: response.data.error, isError: true });
        }
      })
      .catch(() => {
        notification.ref.show({ message: 'Something went wrong', isError: true });
      });
  };
}
