import { View, Image, Dimensions, Modal, TouchableWithoutFeedback } from 'react-native';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-native-material-ui';
import { isEmpty } from 'lodash-es';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Cookies from 'js-cookie';

import Actions from '../../../../RouteActions';
import * as organizationsActions from '../../../../globalActions/organizationsActions';
import { notification } from '../../../../helperFunctions';
import { urls } from '../../../../urls';

//components
import Text from '../../../../components/Text/Text';

//icons
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import defaultOrgAvatar from '../../../../assets/img/default_org_avatar.png';
import defaultBannerImage from '../../../../assets/svg/employer-profile-banner.svg';

// Styles
import { common as commonStyles, $green } from '../../../../generalStyles';
import styles from './styles';

const EmployerProfileTopSection = (props) => {
  const { currentUser, employerProfile, followOrganization } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const openMore = Boolean(anchorEl);
  const avatar = employerProfile.logo_image_thumb ? { uri: employerProfile.logo_image_thumb } : defaultOrgAvatar;
  const bannerImage = employerProfile.cover_picture_image
    ? { uri: employerProfile.cover_picture_image.image_url }
    : defaultBannerImage;
  const isEmptyCurrentUser = isEmpty(currentUser);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    if (params.has('rp')) {
      const referId = params.get('rp');
      if (referId !== 'null') {
        sessionStorage.setItem('referId', referId);
      } else {
        sessionStorage.removeItem('referId', referId);
      }
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const path = window.location.pathname.split('/')[2];
    const shouldViewOpenings = params.get('viewOpenings') === 'true';
    const orgIdentifier = employerProfile.general_setting_identifier;
    if (shouldViewOpenings && orgIdentifier === path) {
      window.open(`${urls.browseAll}?org=${orgIdentifier}`, '_self');
    }
  }, [employerProfile]);

  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
  };

  const handleCopy = () => {
    handleCloseMore();
    navigator.clipboard.writeText(location.href);
    notification.ref.show({ message: 'Link copied.', isError: false });
  };

  return (
    <View style={styles.root}>
      <View>
        <Image source={bannerImage} style={{ height: 184 }} resizeMode="cover" />
      </View>
      <View style={{ paddingTop: 40, paddingBottom: 12, paddingHorizontal: 30 }}>
        <Image
          source={avatar}
          style={{
            width: 82,
            height: 82,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: '#CCCCCC',
            backgroundColor: '#FFFFFF',
            position: 'absolute',
            top: -60,
          }}
        />
        <Text style={{ fontSize: 24, fontWeight: '700' }}>{employerProfile.name}</Text>
        <Text style={[styles.description, { fontSize: 16 }]}>
          {(employerProfile.industry && employerProfile.industry.name) || ''}
        </Text>
        <Text style={[styles.description, { fontSize: 16 }]}>
          {(employerProfile.organization_headquarter && employerProfile.organization_headquarter.full_address) || ''}
        </Text>
        <Text style={[styles.description, { fontSize: 18 }]}>
          {(employerProfile.organization_size && employerProfile.organization_size.name) || ''}
        </Text>
      </View>
      <View style={[commonStyles.row, { paddingVertical: 12, paddingHorizontal: 30 }]}>
        {/* <Button
          primary={true}
          text="Follow"
          upperCase={false}
          style={{
            container: {
              height: 40,
              width: 100,
              backgroundColor: $green,
              borderRadius: 4,
            },
            text: {
              color: "#FFFFFF",
            },
          }}
          onPress={() =>
            isEmptyCurrentUser
              ? Actions.login()
              : followOrganization(employerProfile.name)
          }
        /> */}
        <Button
          text="View Openings"
          upperCase={false}
          style={{
            container: {
              height: 40,
              width: 124,
              backgroundColor: $green,
              borderRadius: 4,
              paddingHorizontal: 12,
              paddingVertical: 11,
            },
            text: {
              color: '#FFFFFF',
              fontSize: 14,
            },
          }}
          onPress={() => Actions.browseAll(props.employerProfile.general_setting_identifier, props.currentUser)}
        />
        {!isEmptyCurrentUser && (
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClickMore}
              style={{
                height: 40,
                width: 42,
                borderColor: '#F2F2F2',
                borderRadius: 4,
                borderWidth: 1,
                borderStyle: 'solid',
                marginLeft: 8,
              }}
            >
              <MoreVertIcon style={{ color: '#C2C2C2' }} />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={openMore}
              onClose={handleCloseMore}
              PaperProps={{
                style: {
                  marginTop: 50,
                },
              }}
              MenuListProps={{ style: { padding: 0 } }}
            >
              <MenuItem key="copy-link" onClick={handleCopy}>
                <ListItemIcon style={{ minWidth: 40 }}>
                  <InsertLinkIcon />
                </ListItemIcon>
                <ListItemText>Copy Link</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        )}
      </View>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.authReducer.currentUser,
    employerProfile: state.organizationsReducer.organizationDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    followOrganization: (organizationName) => {
      dispatch(organizationsActions.followOrganization(organizationName));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EmployerProfileTopSection));
