import React, { useState } from 'react';
import { connect } from 'react-redux';
import { View, Image } from 'react-native';
import { Button } from 'react-native-material-ui';

//components
import Text from '../../../../components/Text/Text';

//images
import defaultUserAvatar from '../../../../assets/img/default_user_avatar.png';
import defaultOrgAvatar from '../../../../assets/img/default_org_avatar.png';
import copyGreenSVG from '../../../../assets/svg/copy-green.svg';

//actions
import * as actions from './actions';
import { notification } from '../../../../helperFunctions';

//styles
import styles from './styles';
import { common as commonStyles } from '../../../../generalStyles';

const QuickLinks = (props) => {
  const { currentUser, userOrganizations, currentSelectedOrg } = props;

  const selectedOrg = userOrganizations.find(
    (organizations) => organizations.organization.id === currentSelectedOrg.id
  );
  const orgAvatar =
    selectedOrg && selectedOrg.organization.logo_image_thumb
      ? { uri: selectedOrg.organization.logo_image_thumb }
      : defaultOrgAvatar;

  const userAvatar = (currentUser && currentUser.avatar_thumb) || defaultUserAvatar;

  const handleCopy = (organization) => {
    navigator.clipboard.writeText(`${organization.employer_profile_link}?rp=${currentUser.slug}`);
    notification.ref.show({ message: 'Public profile link copied.', isError: false });
  };

  return (
    <View style={styles.root}>
      {selectedOrg && selectedOrg.label != 'Boon' && (
        <View>
          <View style={[commonStyles.row, commonStyles.alignItemsCenter, { marginBottom: 7, marginTop: 17 }]}>
            <Image style={[styles.logoImg]} source={orgAvatar} resizeMode="contain" />
            <Text style={styles.titleText}>{selectedOrg.organization.name}&nbsp;PUBLIC PROFILE</Text>
          </View>
          <View style={[commonStyles.row, commonStyles.alignItemsCenter, styles.linkBox]}>
            <View style={[styles.linkContent, commonStyles.flex1]}>
              <Text style={[styles.linkText, commonStyles.textEllipsis]}>{`${selectedOrg.employer_profile_link}?rp=${currentUser.slug}`}</Text>
            </View>
            <Button
              icon={<Image style={[styles.copyImg]} source={copyGreenSVG} resizeMode="contain" />}
              text=""
              style={{
                container: styles.copyButton,
              }}
              onPress={() => {
                navigator.clipboard.writeText(`${selectedOrg.employer_profile_link}?rp=${currentUser.slug}`);
                notification.ref.show({ message: 'Public profile link copied.', isError: false });
              }}
            />
          </View>
        </View>
      )}

      {selectedOrg && selectedOrg.label != 'Boon' && (
        <View>
          <View style={[commonStyles.row, commonStyles.alignItemsCenter, { marginBottom: 7, marginTop: 17 }]}>
            <Image style={[styles.logoImg]} source={orgAvatar} resizeMode="contain" />
            <Text style={styles.titleText}>My Refferal Link</Text>
          </View>
          <View style={[commonStyles.row, commonStyles.alignItemsCenter, styles.linkBox]}>
            <View style={[styles.linkContent, commonStyles.flex1]}>
              <Text style={[styles.linkText, commonStyles.textEllipsis]}>{`${selectedOrg.employer_job_link}?rp=${currentUser.slug}`}</Text>
            </View>
            <Button
              icon={<Image style={[styles.copyImg]} source={copyGreenSVG} resizeMode="contain" />}
              text=""
              style={{
                container: styles.copyButton,
              }}
              onPress={() => {
                navigator.clipboard.writeText(`${selectedOrg.employer_job_link}?rp=${currentUser.slug}`);
                notification.ref.show({ message: 'Job Portal link copied.', isError: false });
              }}
            />
          </View>
        </View>
      )}

      {((currentSelectedOrg.label == 'Boon' && userOrganizations) || []).map((org, index) => {
        const organization = org.organization;
        const orgAvatar = organization.logo_image_thumb ? { uri: organization.logo_image_thumb } : defaultOrgAvatar;
        if (organization.show_public_link) {
          return (
            <View key={`org-${index}`}>
              <View style={[commonStyles.row, commonStyles.alignItemsCenter, { marginBottom: 7, marginTop: 17 }]}>
                <Image style={[styles.logoImg]} source={orgAvatar} resizeMode="contain" />
                <Text style={styles.titleText}>{organization.name}&nbsp;PUBLIC PROFILE</Text>
              </View>
              <View style={[commonStyles.row, commonStyles.alignItemsCenter, styles.linkBox]}>
                <View style={[styles.linkContent, commonStyles.flex1]}>
                  <Text style={[styles.linkText, commonStyles.textEllipsis]}>{`${organization.employer_profile_link}?rp=${currentUser.slug}`}</Text>
                </View>
                <Button
                  icon={<Image style={[styles.copyImg]} source={copyGreenSVG} resizeMode="contain" />}
                  text=""
                  style={{
                    container: styles.copyButton,
                  }}
                  onPress={() => handleCopy(organization)}
                />
              </View>
            </View>
          );
        }
      })}
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.authReducer.currentUser,
    userOrganizations: state.organizationsReducer.userOrganizations,
    currentSelectedOrg: state.authReducer.currentSelectedOrg,
  };
};

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(QuickLinks);
