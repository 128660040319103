import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { isEmpty } from 'lodash-es';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import * as actions from '../actions';
import LinkedInLogo from '../../../assets/img/linkedinIcon.svg';
import ReferralSuccessIcon from '../../../assets/svg/ReferralSuccessIcon.jsx';
import { checkStrengthQuestion, normalizeCandidateLinkedinData, notification } from '../../../helperFunctions';
import responseCodes from '../../../constants/responseCodes';
import ReduxFormAsyncSelectLatest from '../../../components/ReactAsyncSelect/ReduxFormAsyncSelect.js';
import { loadDefaultSkillOptions } from './defualtSkill';
import * as skillService from '../../../services/skillService';

import './StrengthenJobForm.css';
import LocationSelect from '../../../components/LocationSelect/LocationSelect.js';

const BorderLinearProgress = withStyles(() => ({
  root: { height: 8, borderRadius: 8 },
  colorPrimary: { backgroundColor: '#D3DAE6' },
  bar: { backgroundColor: '#0FBC71' },
}))(LinearProgress);
const PROGRESS_VALUES = {
  base: 20,
  title: 0,
  employer: 0,
  location: 0,
  skills: 0,
  joiningDate: 0,
  license_available: 0,
  diversity_hire: 0,
  links: 0,
  files: 0,
};

const StrengthenJobForm = ({
  message,
  appliedByName,
  appliedByEmail,
  referred_by,
  strengthQuestions,
  handleStrengthChange,
  handleStrengthLocationChange,
  handleSetSkills,
  skills,
  getSkills,
  orgId,
  diversityQuestion,
  setDiversityQuestion,
  checkStrengthSubmit,
  formValues,
  referUsers,
  numberOfLinks,
  userCvsArray,
  userPDFfiles,
  newOptions,
  setNewOptions,
  handleStrengthFormData,
  handleLicenceQuestion,
  candidateLinkedinData,
  setStrengthQuestions,
}) => {

  const progressBarPercent = formValues.job_id.organization_name === 'Connect Pediatrics' ? Math.ceil(80 / 9) : 80 / 8;
  const [progressBar, setProgressBar] = useState(20);
  const [selected, setSelected] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const linksHash = {
    ln: 'linkedin',
    po: 'Portfolio',
    pw: 'Website',
    re: 'Resume',
  };
  const calculateStrength = () => {
    Object.keys(strengthQuestions).forEach((question) => {
      if (question === 'skills') {
        if (strengthQuestions[question].length) {
          PROGRESS_VALUES.skills = progressBarPercent;
        } else {
          PROGRESS_VALUES.skills = 0;
        }
      } else if (typeof strengthQuestions[question] == 'string' && strengthQuestions[question].trim() !== '') {
        PROGRESS_VALUES[question] = progressBarPercent;
      } else if (strengthQuestions[question] === '') {
        PROGRESS_VALUES[question] = 0;
      } else if (question === 'location') {
        if (strengthQuestions.location !== '') {
          PROGRESS_VALUES[question] = progressBarPercent;
        }
      }
      const totalProgress = Object.values(PROGRESS_VALUES).reduce((a, b) => a + b);
      setProgressBar(Math.min(totalProgress, 100));
    });
  };
  const calculateStrengthResumeAndLinks = () => {
    if (userCvsArray.length || userPDFfiles.length) {
      PROGRESS_VALUES.files = progressBarPercent;
    }
    const links = numberOfLinks.map((link) => link.value).join();
    if (!isEmpty(links)) {
      PROGRESS_VALUES.links = progressBarPercent;
    }
    const totalProgress = Object.values(PROGRESS_VALUES).reduce((a, b) => a + b);
    setProgressBar(Math.min(totalProgress, 100));
  };
  const calculateStrengthDiversity = () => {
    if (diversityQuestion) {
      PROGRESS_VALUES.diversity_hire = progressBarPercent;
    } else {
      PROGRESS_VALUES.diversity_hire = 0;
    }
    const totalProgress = Object.values(PROGRESS_VALUES).reduce((a, b) => a + b);
    setProgressBar(Math.min(totalProgress, 100));
  };

  useEffect(() => {
    if (!checkStrengthQuestion(strengthQuestions, diversityQuestion)) {
      handleStrengthFormData(true);
    } else if (strengthQuestions.license_available) {
      handleLicenceQuestion(false);
      handleStrengthFormData(false);
    } else {
      handleStrengthFormData(false);
    }
  }, [strengthQuestions, diversityQuestion]);


  const loadOptions = (searchText, callback) => {
    const parameters = `?organization_id=${orgId}&search_term=${encodeURIComponent(searchText)}`;
    skillService.getSkills(parameters).then((response) => {
      if (response.data.response_code === responseCodes.OK) {
        return callback(response.data.payload.skills);
      }
    });
  };

  const loadSuggestions = debounce(loadOptions, 1000);

  useEffect(() => {
    getSkills(orgId);
    loadDefaultSkillOptions(orgId).then((response) => {
      setDefaultOptions(response);
    });
  }, []);

  useEffect(() => {
    calculateStrength();
  }, [strengthQuestions]);

  useEffect(() => {
    calculateStrengthDiversity();
    calculateStrengthResumeAndLinks();
  }, [diversityQuestion]);


  useEffect(() => {
    if (!isEmpty(candidateLinkedinData)) {
      const linkedinData = normalizeCandidateLinkedinData(candidateLinkedinData);
      setSelected(linkedinData.skills);
      setNewOptions(linkedinData.skills);
      setStrengthQuestions(linkedinData);
    }
  }, [candidateLinkedinData]);

  const handleLocationOnChange = (locObj) => {
    if (locObj) {
      handleStrengthLocationChange(locObj, 'location');
    } else {
      handleStrengthLocationChange( '', 'location');
    }
  };

  return (
    <div className="Strengthen-job-form-container">
      <div className="candidate-info-top">Candidate Information</div>
      <div className="candidate-info">
        <div className="name">{appliedByName}</div>
        <div className="email">{appliedByEmail}</div>
        <div className="social-links">
          {numberOfLinks
            .filter(({ value }) => !!value)
            .map((links) =>
              links.type === 'ln' ? (
                <img src={LinkedInLogo} alt="in" />
              ) : (
                <div className="resume">{linksHash[links.type]}</div>
              )
            )}
          {userCvsArray.length || userPDFfiles.length ? <DescriptionOutlinedIcon className="resume-icon" /> : null}
        </div>
      </div>
      {formValues.referred && (
        <>
          <div className="employer-referral-top">Referral Provider</div>
          <div className="employer-referral">{referUsers[0].label}</div>
        </>
      )}
      <div className="employer-message-top">Message to Employer</div>
      <div className="employer-message">{message}</div>
      <div className="strength-bar">
        <div className="strength-name"> Strengthen Application </div>
        <div className="strength-sub">
          Application strength
          <span className="progress-bar">{progressBar}%</span>
        </div>
        <BorderLinearProgress variant="determinate" value={progressBar} />
      </div>
      <div className="question-text">
        {!checkStrengthSubmit
          ? 'What is your current title?'
          : strengthQuestions.title && (
              <span className="submitted-question">
                What is your current title? <span className="answer">{strengthQuestions.title}</span>
              </span>
            )}
      </div>
      {!checkStrengthSubmit && (
        <TextField
          id="outlined-multiline-static"
          label="Current title"
          className="text-input"
          size="small"
          InputProps={{
            style: {
              fontWeight: 400,
              color: '#000000',
              backgroundColor: '#ffffff',
            },
          }}
          // value={appliedByName}
          variant="outlined"
          value={strengthQuestions.title}
          onChange={(e) => handleStrengthChange(e, 'title')}
        />
      )}
      <div className="question-text">
        {!checkStrengthSubmit
          ? 'Who is your current employer?'
          : strengthQuestions.employer && (
              <span className="submitted-question">
                Who is your current employer? <span className="answer">{strengthQuestions.employer} </span>
              </span>
            )}
      </div>
      {!checkStrengthSubmit && (
        <TextField
          id="outlined-multiline-static"
          label="Current employer"
          className="text-input"
          size="small"
          InputProps={{
            style: {
              fontWeight: 400,
              color: '#000000',
              backgroundColor: '#ffffff',
            },
          }}
          rows={6}
          variant="outlined"
          value={strengthQuestions.employer}
          onChange={(e) => handleStrengthChange(e, 'employer')}
        />
      )}
      <div className="question-text">
        {!checkStrengthSubmit
          ? 'Current Location'
          : strengthQuestions.location.full_address && (
              <span className="submitted-question">
                Current Location <span className="answer">{strengthQuestions.location.full_address}</span>
              </span>
            )}
      </div>
      {!checkStrengthSubmit && (
        <LocationSelect
          id="outlined-multiline-static"
          label="Current location"
          className="text-input"
          disableAutoFetch
          iconPosition=""
          value={{ full_address: strengthQuestions.location.full_address }}
          onChange={handleLocationOnChange}
        />
      )}
      <div className="question-text">
        {!checkStrengthSubmit
          ? 'What are you good at?'
          : !!strengthQuestions.skills.length && (
              <span className="submitted-question">
                What are they good at?
                <span className="answer">{strengthQuestions.skills.map((skill) => ` ${skill.name} `)}</span>
              </span>
            )}
      </div>
      {!checkStrengthSubmit && (
        <ReduxFormAsyncSelectLatest
          name="skills"
          className="light max-width list multi"
          options={[...newOptions, ...(skills || [])]}
          value={selected}
          isCreatable={true}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isMulti
          defaultOptions={defaultOptions}
          isClearable={false}
          onChange={(e) => {
            const newIndex = e.findIndex((s) => s.__isNew__);
            if (newIndex >= 0) {
              const newValue = {
                id: ((newOptions[0] && newOptions[0].id) || 0) - 1,
                name: e[newIndex].value,
                isNew: true,
              };
              setNewOptions([newValue, ...newOptions]);
              const newSelectedValues = [...e.slice(0, newIndex), newValue, ...e.slice(newIndex + 1)];
              setSelected(newSelectedValues);
              handleSetSkills(newSelectedValues);
            } else {
              setSelected(e);
              handleSetSkills(e);
              setNewOptions(e.filter((val) => val.id < 0));
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Tab' || e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          placeholder="Search skills to add"
          simpleValue
          labelKey="name"
          valueKey="id"
          skillLimit={0}
          loadOptions={loadSuggestions}
        />
      )}

      {!checkStrengthSubmit && formValues.job_id.organization_name === 'Connect Pediatrics' && (
        <>
          <div className="question-text">
            Are you professionally certified/licensed (e.g., Certified Teacher, Registered Nurse, IT Certification)?
          </div>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <TextField
              select
              value={strengthQuestions.license_available}
              onChange={(e) => handleStrengthChange(e, 'license_available')}
              variant="outlined"
              label="License Available"
              InputLabelProps={{ shrink: true }}
              className="license-selector"
              InputProps={{
                shrink: true,
                style: {
                  backgroundColor: '#ffffff',
                  height: 40
                },
              }}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </TextField>
          </FormControl>
        </>
      )}

      {checkStrengthSubmit && formValues.job_id.organization_name === 'Connect Pediatrics' && (
        <div className="question-text">
          <span className="submitted-question">
            Are you professionally certified/licensed?
            <span className="answer">&nbsp;{strengthQuestions.license_available}</span>
          </span>
        </div>
      )}

      <div className="question-text">
        {!checkStrengthSubmit
          ? 'When would be your soonest start date?'
          : strengthQuestions.joiningDate && (
              <span className="submitted-question">
                When would be your soonest start date?
                <span className="answer">&nbsp;{moment(strengthQuestions.joiningDate).format('MM/DD/YYYY')}</span>
              </span>
            )}
      </div>
      {!checkStrengthSubmit && (
        <form className="text-input" noValidate>
          <TextField
            id="date"
            type="date"
            className="text-input"
            size="small"
            variant="outlined"
            InputProps={{
              shrink: true,
              style: {
                backgroundColor: '#ffffff',
                color: strengthQuestions.joiningDate ? 'black' : '#727272',
                fontFamily: 'Roboto-Regular',
              },
            }}
            onChange={(e) => {
              handleStrengthChange(e, 'joiningDate');
            }}
            inputProps={{
              min: moment().format('MM/DD/YYYY'),
            }}
          />
        </form>
      )}
      <div className="question-text">
        {!checkStrengthSubmit
          ? 'Would you consider yourself a diversity hire?'
          : diversityQuestion && (
              <span className="submitted-question">
                Would you consider yourself a diversity hire?
                <span className="answer">&nbsp;{diversityQuestion ? 'Yes' : 'No'}</span>
              </span>
            )}
      </div>
      {/* Except LGBTQ */}
      {!checkStrengthSubmit && (
        <Checkbox
          color="secondary"
          name="diversityHire"
          style={{ color: '#727272' }}
          id="diversityHire"
          checked={diversityQuestion}
          onChange={(e) => setDiversityQuestion(e.target.checked)}
        />
      )}
      {!checkStrengthSubmit && <span className="diversity-name">Yes</span>}
      {checkStrengthSubmit && (
        <div className="success-image-conatiner">
          <ReferralSuccessIcon />
          <div className="success-heading">{progressBar === 100 ? 'Amazing!' : 'Success!'}</div>
          <div className="success-text">
            {progressBar === 100
              ? 'You got your Application strength to 100%!'
              : 'Your Application has been completed successfully!'}
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  skills: state.trackerReducer.skills,
  candidateLinkedinData: state.trackerReducer.candidateLinkedinData,
});
const mapDispatchToProps = (dispatch) => ({
  getSkills: (organizationId) => {
    dispatch(actions.getSkills(organizationId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StrengthenJobForm);
